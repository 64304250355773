import { createContext, useContext, useState, ReactNode } from "react";

type NotificationContextType = {
    showNotification: boolean;
    triggerNotification: () => void;
    hideNotification: () => void;
};

export const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

export function useNotification() {
    const context = useContext(NotificationContext);
    if (!context) {
        throw new Error("useNotification must be used within a NotificationProvider");
    }
    return context;
}

export function NotificationProvider({ children }: { children: ReactNode }) {
    const [showNotification, setShowNotification] = useState(false);

    const triggerNotification = () => setShowNotification(true);
    const hideNotification = () => setShowNotification(false);

    return (
        <NotificationContext.Provider
            value={{ showNotification, triggerNotification, hideNotification }}
        >
            {children}
        </NotificationContext.Provider>
    );
}
