import { Phase, Plot } from "@/types";
import { isAvailable } from "./Plot";

export const getFavoritePlots = (
  plots: { [key: Plot["id"]]: Plot },
  statuses: Phase["statuses"],
  items: Plot["id"][]
) => {
  return items.reduce<{
    available: Plot[];
    unavailable: Plot[];
  }>(
    (acc, id) => {
      const plot = plots[id];
      if (plot === undefined) return acc;

      const available = isAvailable(statuses[plot.status]);

      if (available) {
        acc.available.push(plot);
      } else {
        acc.unavailable.push(plot);
      }

      return acc;
    },
    { available: [], unavailable: [] }
  );
};
