import { useEffect, useRef } from "react";
import { useLocation, useNavigation } from "react-router-dom";

type ScrollTargetProps = {
  id: string | number;
  children?: JSX.Element | JSX.Element[];
  className?: string;
  behaviour?: "smooth" | "auto";
  as?: React.ElementType;
};

export default function ScrollTarget({
  children,
  behaviour = "smooth",
  as,
  ...rest
}: Readonly<ScrollTargetProps>) {
  const target = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const navigation = useNavigation();
  const Component = as || "div";

  useEffect(() => {
    const hasTarget = target.current;
    const isTarget = location.hash === `#${target.current?.id}`;
    const isReady = navigation.state === "idle";

    if (hasTarget && isTarget && isReady) {
      target.current.scrollIntoView({ behavior: behaviour });
    }
  }, [location.hash, navigation.state, behaviour]);

  return (
    <Component ref={target} {...rest}>
      {children}
    </Component>
  );
}
