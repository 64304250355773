import clsx from "clsx";

type CountProps = {
  className?: string;
  children: React.ReactNode;
};

export default function Count({ className, children }: Readonly<CountProps>) {
  return (
    <span
      className={clsx(
        className,
        "px-1 h-4 text-xs rounded-full text-primary bg-secondary box-content text-center"
      )}
    >
      {children}
    </span>
  );
}
