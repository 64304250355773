import { Plot } from "@/types";
import {
  faHeart,
  faTimes,
  faTrash,
} from "@awesome.me/kit-b9851c3d09/icons/classic/regular";
import { faHeart as solidFaHeart } from "@awesome.me/kit-b9851c3d09/icons/classic/solid";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { useState } from "react";
import useFavorites from "@/hooks/useFavorites";

type ButtonProps = {
  className?: string;
  variant: "primary" | "secondary" | "ghost";
  children: any;
  icon?: IconProp;
  onClick?: () => void;
};

function Button({
  className,
  variant,
  children,
  icon,
  onClick,
}: Readonly<ButtonProps>) {
  const hasIcon = !!icon;
  const classes = clsx(
    className,
    "group py-2 px-3 rounded-[3px] flex gap-2 items-center transition-colors justify-center text-sm",
    variant === "primary" &&
      "text-secondary-contrast bg-secondary hover:bg-primary hover:text-primary-contrast",
    variant === "secondary" &&
      "text-primary-contrast bg-primary hover:bg-white hover:text-secondary-contrast",
    variant === "ghost" &&
      "text-secondary-contrast bg-white hover:bg-secondary hover:text-secondary-contrast"
  );

  const iconClasses = clsx(
    "transition-colors",
    variant === "primary" &&
      "text-primary lg:group-hover:text-secondary-contrast",
    variant === "secondary" &&
      "text-primary-contrast lg:group-hover:text-primary",
    variant === "ghost" && "text-primary "
  );

  return (
    <button className={classes} onClick={onClick}>
      {hasIcon && <FontAwesomeIcon icon={icon} className={iconClasses} />}
      {children}
    </button>
  );
}

interface LikeButtonProps {
  className?: string;
  plot: Plot;
  children?: React.ReactNode;
  withBackground?: boolean;
  hasOverlay?: boolean;
}

export default function LikeButton({
  className,
  plot,
  children,
  withBackground = true,
  hasOverlay = false,
}: Readonly<LikeButtonProps>) {
  const { favoritedIds, updateFavoriteIds } = useFavorites();
  const isLiked = favoritedIds.includes(plot.id);

  const [showOverlay, setShowOverlay] = useState(false);

  const toggleOverlay = (value: boolean) => {
    setShowOverlay(value);
  };

  const handleClick = () => {
    updateFavoriteIds(plot.id);
  };

  return (
    <>
      <button
        className={clsx(
          className,
          "group flex items-center justify-center sm:absolute w-11 h-11 z-1 text-primary"
        )}
        onClick={() => {
          hasOverlay ? toggleOverlay(true) : handleClick();
        }}
      >
        <div className="relative w-full h-full text-xl">
          {withBackground && (
            <div className="absolute z-10 -translate-x-1/2 -translate-y-1/2 rounded-full top-1/2 left-1/2 h-11 w-11 bg-black/50"></div>
          )}

          <FontAwesomeIcon
            icon={faHeart}
            className="absolute z-20 -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
          />

          <FontAwesomeIcon
            icon={solidFaHeart}
            className={clsx(
              isLiked && "opacity-100",
              "absolute z-10 -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 transition-colors opacity-0 text-current lg:group-hover:opacity-35"
            )}
          />
        </div>
        {children}
      </button>

      {hasOverlay && showOverlay && isLiked && (
        <div className="absolute inset-0 z-20 flex items-center justify-between px-2 bg-secondary/95">
          <span className="inline-block text-sm font-bold max-w-44">
            Weet je zeker dat je deze woning wilt verwijderen?
          </span>

          <div className="flex gap-x-1">
            <Button variant="secondary" onClick={handleClick} icon={faTrash}>
              Ja
            </Button>

            <Button
              variant="ghost"
              onClick={() => toggleOverlay(false)}
              icon={faTimes}
            >
              Nee
            </Button>
          </div>
        </div>
      )}
    </>
  );
}
