import { client } from "@/api/client";
import { keyBy } from "@/services";
import { Phase, Project } from "@/types";
import { QueryClient, queryOptions } from "@tanstack/react-query";
import { LoaderFunctionArgs } from "react-router-dom";

/*
  @link https://axios-http.com/docs/handling_errors
*/
const getPhase = async (
  project: Project["slug"],
  phase: Phase["slug"]
): Promise<Phase> => {
  return await client
    .get(`/${project}/phases/${phase}`)
    .then((response) => response.data)
    .catch((error) => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        throw new Response("Not Found", { status: 404 });
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        throw new Response(error.message, { status: 500 });
      } else {
        // Something happened in setting up the request that triggered an Error
        throw new Response(error.message, { status: 500 });
      }
    });
};

export const query = (project: string, phase: string) => {
  return queryOptions({
    queryKey: ["project", "phase", project, phase],
    queryFn: async () => {
      if (!project || !phase) throw new Response("Not Found", { status: 404 });

      const phaseResponse = await getPhase(project, phase);

      return {
        project: phaseResponse,
        phase: phaseResponse,
        statuses: phaseResponse.statuses,
        plots: keyBy(phaseResponse.plots, "id"),
        hotspots: keyBy(phaseResponse.hotspots, "id"),
        layers: keyBy(phaseResponse.layers, "id"),
      };
    },
  });
};

export const loader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    const { projectSlug, phaseSlug } = params;
    await queryClient.ensureQueryData(query(projectSlug!, phaseSlug!));

    return { projectSlug: projectSlug!, phaseSlug: phaseSlug! };
  };
