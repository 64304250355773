import { isAvailable } from "@/services";
import { Phase, Plot } from "@/types";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";
import NotificationBar from "./NotificationBar";
import useFavorites from "@/hooks/useFavorites";

type WishlistNotificationBarPropsType = {
  plots: { [key: Plot["id"]]: Plot };
  statuses: Phase["statuses"];
};

export default function WishlistNotificationBar({
  plots,
  statuses,
}: Readonly<WishlistNotificationBarPropsType>) {
  const { projectSlug, phaseSlug } = useParams();
  const prefix = `${process.env.NODE_ENV}-${projectSlug}-${phaseSlug}`;
  const wishlistBarKey = `${prefix}-notification-bar`;

  const [closed, setClosed] = useLocalStorage<number | null>(
    wishlistBarKey,
    null
  );

  const { mapFavoritedIdsToPlots } = useFavorites();

  const sold = mapFavoritedIdsToPlots(plots).filter(
    (plot) => !isAvailable(statuses[plot.status])
  );

  const closedMoreThanOneDayAgo =
    closed !== null
      ? new Date().getTime() - closed > 1000 * 60 * 60 * 24
      : false;

  useEffect(() => {
    if (closed !== null) return;

    setClosed(new Date().getTime());
  });

  const text = (amount: number) => {
    if (amount === 1) {
      return `${amount} die op jouw favorietenlijst staat, is inmiddels verkocht.`;
    }
    return `${amount} woningen die op jouw favorietenlijst staan, zijn inmiddels verkocht.`;
  };

  return (
    sold.length > 0 &&
    closedMoreThanOneDayAgo && (
      <NotificationBar>
        <span className="text-base font-semibold !leading-5">
          {text(sold.length)}
        </span>
      </NotificationBar>
    )
  );
}
