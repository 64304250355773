import clsx from "clsx";

type GridProps = {
  children: any;
  className?: string;
};

export default function Grid({ children, className }: Readonly<GridProps>) {
  return (
    <div
      className={clsx("w-full px-6 mx-auto max-w-grid lg:px-auto", className)}
    >
      {children}
    </div>
  );
}
