/**
 * This function accepts a target value and slugifies the string
 */
export function slugify(value: string): string {
  if (!value) {
    console.error(`No value provided: value = ${value}`);
    return "";
  }

  value = value.replace(/^\s+|\s+$/g, ""); // trim
  value = value.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to = "aaaaeeeeiiiioooouuuunc------";
  for (var i = 0, l = from.length; i < l; i++) {
    value = value.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  value = value
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return value;
}
