import { buttonClasses, buttonIconClasses } from "@/services";
import { Phase, Plot } from "@/types";
import { faArrowRight } from "@awesome.me/kit-b9851c3d09/icons/classic/solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { Link, useParams } from "react-router-dom";
import Small from "./Small";
import useFavorites from "@/hooks/useFavorites";

type FavoriteType = {
  plots: { [key: Plot["id"]]: Plot };
  statuses: Phase["statuses"];
};

export default function Favorites({ plots, statuses }: FavoriteType) {
  const { projectSlug, phaseSlug } = useParams();

  const { mapFavoritedIdsToPlots } = useFavorites();
  const likedPlots = mapFavoritedIdsToPlots(plots);

  return (
    likedPlots.length > 0 && (
      <section className="flex flex-col px-6 py-8 bg-secondary lg:p-8">
        <h2 className="mb-2 font-semibold h3 lg:mb-5">
          Mijn favoriete woningen
        </h2>

        <ul className="flex flex-col w-full mb-3 lg:mb-6">
          {likedPlots.map((plot, index) => (
            <li key={plot.id}>
              <hr className="my-0 border-t border-t-black/10" />

              <Small
                plot={plot}
                status={statuses[plot.status]}
                className={"my-3"}
              />

              {index === likedPlots.length - 1 && (
                <hr className="my-0 border-t border-t-black/10" />
              )}
            </li>
          ))}
        </ul>

        <Link
          to={`/projects/${projectSlug}/phases/${phaseSlug}/favorieten`}
          className={clsx("self-start", buttonClasses("secondary"))}
        >
          Inschrijven voor deze woningen
          <FontAwesomeIcon
            icon={faArrowRight}
            className={buttonIconClasses("secondary")}
          />
        </Link>
      </section>
    )
  );
}
