import { faTimes } from "@awesome.me/kit-b9851c3d09/icons/classic/solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

type FieldProps = {
  field: any;
  label: string;
  type?: string;
  className?: string;
};

export default function Text({ field, label, type, className }: FieldProps) {
  const {
    name,
    handleBlur,
    handleChange,
    state: {
      value,
      meta: { errors, isTouched },
    },
  } = field;

  return (
    <fieldset className={clsx(className, "relative col-span-full")}>
      <div className="relative group">
        <label
          htmlFor={name}
          className={clsx(
            "absolute left-4 select-none transition-all duration-100",
            "group-focus-within:!translate-y-1/2  group-focus-within:!small group-focus-within:!font-bold group-focus-within:!text-primary",
            ((isTouched && value !== "") || value !== "") &&
              "translate-y-1/2 small font-bold text-primary",
            value === "" && "translate-y-5 lg:translate-y-full body font-medium"
          )}
        >
          {label}
        </label>

        <input
          id={name}
          type={type}
          name={name}
          value={value}
          onBlur={handleBlur}
          onChange={(e) => handleChange(e.target.value)}
          className={clsx(
            "w-full h-16 px-4 pt-8 pb-3 text-base leading-5 border rounded border-black/25",
            errors.length && "border-primary border-2"
          )}
        />
      </div>

      {errors.length ? (
        <span className="flex items-start gap-x-2">
          <span className="text-primary font-[17px] leading-6">
            <FontAwesomeIcon icon={faTimes} />
          </span>

          <span className="!leading-6 small">{errors.join(",")}</span>
        </span>
      ) : null}
    </fieldset>
  );
}
