import { useNotification } from "@/components";
import { Plot } from "@/types";
import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";

export default function useFavorites() {
  const { projectSlug, phaseSlug } = useParams();
  const [searchParams] = useSearchParams();
  const { triggerNotification } = useNotification();

  const prefix = `${process.env.NODE_ENV}-${projectSlug}-${phaseSlug}`;
  const key = `${prefix}-favorites`;
  const [favoritedIds, setFavoritedIds] = useLocalStorage<Plot["id"][]>(
    key,
    []
  );

  const defaultLiked = [
    searchParams.has("voorkeur_1")
      ? parseInt(searchParams.get("voorkeur_1")!)
      : favoritedIds[0] || null,
    searchParams.has("voorkeur_2")
      ? parseInt(searchParams.get("voorkeur_2")!)
      : favoritedIds[1] || null,
    searchParams.has("voorkeur_3")
      ? parseInt(searchParams.get("voorkeur_3")!)
      : favoritedIds[2] || null,
    searchParams.has("voorkeur_4")
      ? parseInt(searchParams.get("voorkeur_4")!)
      : favoritedIds[3] || null,
    searchParams.has("voorkeur_5")
      ? parseInt(searchParams.get("voorkeur_5")!)
      : favoritedIds[4] || null,
  ];

  /**
   * Add plot ID if it's doesn't already in the list.
   * Remove plot ID if it already exists.
   * Show notification if ${id} would be the 6th ID.
   */
  const updateFavoriteIds = (id: Plot["id"]) => {
    setFavoritedIds((prev) => {
      if (prev.includes(id)) {
        // previously liked, remove from list
        return prev.filter((existing) => id !== existing);
      } else if (prev.length < 5) {
        // not yet liked, and less than 5 liked plots
        return [...prev, id];
      } else {
        // not yet liked, but already 5 liked plots
        triggerNotification();
      }

      return prev;
    });
  };

  /**
   * Map favorited IDs to Plot objects.
   */
  const mapFavoritedIdsToPlots = (plots: { [key: Plot["id"]]: Plot }) => {
    return favoritedIds.reduce<Plot[]>((acc, id) => {
      const result = plots[id];
      if (result === undefined) return acc;

      acc.push(result);
      return acc;
    }, []);
  };

  useEffect(() => {
    setFavoritedIds(defaultLiked.filter((id) => id !== null));
  }, [...defaultLiked, setFavoritedIds]);

  return {
    favoritedIds,
    setFavoritedIds,
    updateFavoriteIds,
    mapFavoritedIdsToPlots,
  };
}
