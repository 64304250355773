import { client } from "@/api/client";
import { Project } from "@/types";
import { QueryClient, queryOptions } from "@tanstack/react-query";
import { LoaderFunctionArgs } from "react-router-dom";

/*
  @link https://axios-http.com/docs/handling_errors
  Note: we dont support projects yet but will in the future
*/
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getProject = async (projectSlug: Project["slug"]): Promise<Project> => {
  return await client
    .get(`/${projectSlug}`)
    .then((response) => response.data)
    .catch((error) => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        throw new Response("Not Found", { status: 404 });
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        throw new Response(error.message, { status: 500 });
      } else {
        // Something happened in setting up the request that triggered an Error
        throw new Response(error.message, { status: 500 });
      }
    });
};

export const query = (slug: string) => {
  return queryOptions({
    queryKey: ["project", "detail", slug],
    queryFn: async () => {
      if (!slug) throw new Response("Not Found", { status: 404 });

      // const project = await getProject(slug);

      // return {
      //   project: project,
      //   plots: keyBy(project.plots, "id"),
      //   statuses: project.statuses,
      //   hotspots: keyBy(project.hotspots, "id"),
      //   layers: keyBy(project.layers, "id"),
      // };
      return {};
    },
  });
};

export const loader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    const { projectSlug } = params;
    await queryClient.ensureQueryData(query(projectSlug!));

    return { projectSlug: projectSlug! };
  };
