import {
  Link as CustomLink,
  LikeButton,
  Placeholder,
  StatusLabel,
} from "@/components";
import { price as formatPrice, isAvailable } from "@/services";
import { formatName } from "@/services/Plot";
import { Plot, Status } from "@/types";
import {
  faBedFront,
  faCompass,
  faInfoCircle,
  faPuzzlePiece,
} from "@awesome.me/kit-b9851c3d09/icons/classic/regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { Link, useParams, useSearchParams } from "react-router-dom";

type PopupProps = {
  plot: Plot;
  status: Status;
  className?: string;
};

export default function MobilePopup({
  plot,
  status,
  className,
}: Readonly<PopupProps>) {
  const { projectSlug, phaseSlug } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    id,
    attachments,
    name,
    room_count,
    living_surface,
    sun_position_outdoor,
  } = plot;

  const onClick = () => {
    searchParams.delete("hotspot");
    setSearchParams(searchParams);
  };

  return (
    <div className={clsx(className)}>
      <button
        className="absolute -translate-x-1/2 top-2 left-1/2"
        onClick={() => onClick()}
      >
        <div className="h-1 rounded-full w-18 bg-primary"></div>
      </button>

      <div className="grid grid-cols-card">
        <figure className="relative overflow-hidden aspect-video">
          <LikeButton plot={plot} className="absolute top-2.5 left-2.5" />
          <Link to={`/projects/${projectSlug}/phases/${phaseSlug}/plots/${id}`}>
            {attachments.length > 0 && attachments[0]?.original ? (
              <img
                src={attachments[0].original}
                srcSet={attachments[0].srcset}
                sizes="33dvw"
                alt={name}
                width="600"
                height="400"
                className="object-cover object-center w-full h-full"
                loading="lazy"
              />
            ) : (
              <Placeholder className="object-cover object-center w-full h-full" />
            )}
          </Link>
        </figure>

        <div className="flex flex-col p-4 gap-y-2 lg:gap-y-6 shrink-0 grow-0 sm:col-span-1">
          {/* Title */}
          <div className="flex flex-col">
            <div className="flex justify-between">
              <h3 className="font-bold h3">
                <Link
                  to={`/projects/${projectSlug}/phases/${phaseSlug}/plots/${id}`}
                >
                  {formatName(plot)}
                </Link>
              </h3>

              <StatusLabel status={status} />
            </div>

            <span className="body !leading-none">
              {isAvailable(status) ? formatPrice(plot) : "Onbeschikbaar"}
            </span>
          </div>

          {/* Icons */}
          <div className="flex justify-between text-base lg:gap-8">
            {living_surface && (
              <span className="flex items-center gap-x-2 lg:flex-col lg:basis-[33%]">
                <span className="text-xl">
                  <FontAwesomeIcon
                    icon={faPuzzlePiece}
                    className="my-1.5 text-primary"
                  />
                </span>
                <span
                  className="text-sm leading-6 line-clamp-1 text-nowrap"
                  title={living_surface}
                >
                  {living_surface} m<sup>2</sup>
                </span>
              </span>
            )}

            {room_count && (
              <span className="flex items-center gap-x-2 lg:flex-col lg:basis-[33%]">
                <span className="text-xl">
                  <FontAwesomeIcon
                    icon={faBedFront}
                    className="my-1.5 text-primary"
                  />
                </span>
                <span
                  className="text-sm line-clamp-1 text-nowrap"
                  title={room_count}
                >
                  {room_count}
                </span>
              </span>
            )}

            {sun_position_outdoor && (
              <span className="flex items-center gap-x-2 lg:flex-col lg:basis-[33%]">
                <span className="text-xl">
                  <FontAwesomeIcon
                    icon={faCompass}
                    className="my-1.5 text-primary"
                  />
                </span>
                <span
                  className="text-sm line-clamp-1"
                  title={sun_position_outdoor}
                >
                  {sun_position_outdoor}
                </span>
              </span>
            )}
          </div>

          {/* Buttons */}
          <CustomLink
            to={`/projects/${projectSlug}/phases/${phaseSlug}/plots/${id}`}
            variant="primary"
            icon={faInfoCircle}
            className="grow-1"
          >
            <span className="whitespace-nowrap">Meer details</span>
          </CustomLink>
        </div>
      </div>
    </div>
  );
}
