import { useEffect, useRef } from "react";

import { Fancybox as NativeFancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/carousel/carousel.css";
import "@fancyapps/ui/dist/carousel/carousel.thumbs.css";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import "./fancybox.css";

type FancyboxProps = {
  className?: string;
  options?: object;
  delegate?: any;
  children?: any;
};

export default function Fancybox(props: Readonly<FancyboxProps>) {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const defaults = {
      ...NativeFancybox.defaults,
      parentEl: containerRef.current?.closest("#dom-wrapper"),
      Hash: false,
    };

    const container = containerRef.current;

    const delegate = props.delegate ?? "[data-fancybox]";
    const options = {
      ...defaults,
      ...props.options,
    };

    NativeFancybox.bind(containerRef.current, delegate, options);

    return () => {
      NativeFancybox.unbind(container);
      NativeFancybox.close();
    };
  });

  return (
    <div ref={containerRef} className={props.className}>
      {props.children}
    </div>
  );
}
