import { Status as StatusType } from "@/types";
import clsx from "clsx";

type StatusProps = {
  status: StatusType;
  className?: string;
};

export default function StatusLabel({
  status,
  className,
}: Readonly<StatusProps>) {
  return (
    <div
      className={clsx(
        "flex flex-row-reverse items-center gap-x-2 small",
        className
      )}
    >
      <span>{status.name}</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
      >
        <circle cx="9" cy="9" r="6" fill={status.color} />
        <circle
          cx="9"
          cy="9"
          r="7.5"
          stroke={status.color}
          strokeOpacity="0.25"
          strokeWidth="3"
        />
      </svg>
    </div>
  );
}
