import { Plot } from "@/types";
import { Select } from "antd";
import clsx from "clsx";
import { useSearchParams } from "react-router-dom";

type RoomFilterProps = {
  values: Plot["room_count"][];
  className?: string;
};

export default function RoomFilter({
  values,
  className,
}: Readonly<RoomFilterProps>) {
  const [searchParams, setSearchParams] = useSearchParams();

  const rooms = values
    .sort((a, b) => parseInt(a) - parseInt(b))
    .map((rooms) => ({ label: `${rooms}+ kamers`, value: rooms }));

  const onChange = (value: string) => {
    if (value === "") {
      searchParams.delete("room_count");
    } else {
      searchParams.set("room_count", value);
    }

    setSearchParams(searchParams);
  };

  return (
    <fieldset className="flex">
      <Select
        className={clsx(className, "w-full h-full")}
        defaultValue={"Aantal kamers"}
        showSearch={false}
        options={rooms}
        onChange={onChange}
        getPopupContainer={(e) => e.closest("main")}
      />
    </fieldset>
  );
}
