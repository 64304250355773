import NotificationBar from "./NotificationBar";
import { useEffect } from "react";

type FavoritesNotificationBarProps = {
  onClose: () => void;
};

export default function FavoritesNotificationBar({
  onClose,
}: Readonly<FavoritesNotificationBarProps>) {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 5000);
    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <NotificationBar onClose={onClose}>
      <span className="text-base font-semibold !leading-5">
        Je kunt maximaal 5 favorieten toevoegen.
      </span>
    </NotificationBar>
  );
}
