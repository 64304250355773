import { Hotspot, Layer, Phase, Plot } from "@/types";
import Card from "./Card";
import { useRecommendations } from "@algolia/recommend-react";
import recommend from "@algolia/recommend";

type AlgoliaRecommendations = {
  recommendations: ({
    objectID: string;
    _score: number;
    _highlightResult: object;
  } & Plot)[];
};

const recommendClient = recommend(
  process.env.REACT_APP_ALGOLIA_APP_ID!,
  process.env.REACT_APP_ALGOLIA_APP_SECRET!
);

type RecommendationsType = {
  current: Plot;
  plots: { [key: Plot["id"]]: Plot };
  hotspotsByPlotId: { [key: Plot["id"]]: Hotspot };
  statuses: Phase["statuses"];
  layers: { [key: Layer["slug"]]: Layer };
  className?: string;
};

export default function Recommendations({
  current,
  plots,
  hotspotsByPlotId,
  statuses,
  layers,
  className,
}: RecommendationsType) {
  const { recommendations }: AlgoliaRecommendations = useRecommendations({
    model: "related-products",
    indexName: "plots",
    recommendClient,
    objectIDs: [current.id.toString()],
    threshold: 0,
  });

  const items = recommendations
    .reduce<Plot[]>((acc, recommendation) => {
      const plot = plots[parseInt(recommendation.objectID)];
      if (!plot) return acc;
      const hotspot = hotspotsByPlotId[plot.id];
      if (!hotspot) return acc;
      acc.push(plot);
      return acc;
    }, [])
    .slice(0, 2);

  return (
    items.length > 0 && (
      <section className={className}>
        <h2 className="h2">Andere woningen die je misschien leuk vindt</h2>
        <ul className="flex flex-col gap-4 lg:flex-row lg:gap-x-12">
          {items.map((recommendation, i) => {
            const hotspot = hotspotsByPlotId[recommendation.id];

            return (
              <li key={recommendation.id}>
                {i === 1 && (
                  <div className="w-full h-px mb-4 bg-black/10 lg:hidden"></div>
                )}

                <Card
                  plot={recommendation}
                  status={statuses[recommendation.status]}
                  layer={layers[hotspot.layer_id]}
                  hotspot={hotspot}
                />
              </li>
            );
          })}
        </ul>
      </section>
    )
  );
}
