import { generateRange } from "@/services";
import { Plot } from "@/types";
import { Select } from "antd";
import { useSearchParams } from "react-router-dom";

type SurfaceFilterProps = {
  values: Plot["living_surface"][];
};

export default function SurfaceFilter({
  values,
}: Readonly<SurfaceFilterProps>) {
  const [searchParams, setSearchParams] = useSearchParams();

  const surfaces = generateRange(values, "down").map((living_surface) => ({
    label: (
      <span>
        Vanaf {living_surface}m<sup>2</sup>
      </span>
    ),
    value: living_surface,
  }));

  const onChange = (value: string) => {
    if (value === "") {
      searchParams.delete("living_surface");
    } else {
      searchParams.set("living_surface", value);
    }

    setSearchParams(searchParams);
  };

  return (
    <fieldset className="flex">
      <Select
        className="w-full"
        defaultValue={"Woonoppervlakte"}
        showSearch={false}
        options={surfaces}
        onChange={onChange}
        getPopupContainer={(e) => e.closest("main")}
      />
    </fieldset>
  );
}
