import defaultImage from "./icons/600x400.svg";

export default function Placeholder({
  image = defaultImage,
  width = 600,
  height = 400,
  alt = "placeholder",
  ...rest
}) {
  return <img src={image} width={width} height={height} alt={alt} {...rest} />;
}
