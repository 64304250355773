import clsx from "clsx";

export const buttonClasses = (variant: "primary" | "secondary" | "ghost") =>
  clsx(
    "group py-2 px-3 rounded-[3px] flex gap-2 items-center transition-colors justify-center small text-balance",
    variant === "primary" &&
      "text-secondary-contrast bg-secondary hover:bg-primary hover:text-primary-contrast",
    variant === "secondary" &&
      "text-primary-contrast bg-primary hover:bg-white hover:text-secondary-contrast",
    variant === "ghost" &&
      "text-secondary-contrast bg-white hover:bg-secondary hover:text-secondary-contrast"
  );

export const buttonIconClasses = (variant: "primary" | "secondary" | "ghost") =>
  clsx(
    "transition-colors text-[1rem]",
    variant === "primary" && "text-primary group-hover:text-primary-contrast",
    variant === "secondary" && "text-primary-contrast group-hover:text-primary",
    variant === "ghost" && "text-primary "
  );
