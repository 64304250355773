import {
  PlotPage,
  PhasePage,
  Root,
  RootErrorPage,
  ProjectRoot,
} from "@/layouts";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";

import {
  Route,
  RouterProvider,
  createHashRouter,
  createRoutesFromElements,
} from "react-router-dom";
import "./index.css";
import Favorites from "./layouts/Favorites";
import { PhaseLoader, PlotLoader, ProjectLoader } from "./loaders";
import { NotificationProvider } from "./components/Provider/NotificationContext";

import { config, dom } from "@fortawesome/fontawesome-svg-core";
import { useEffect, useRef, useState } from "react";
import { StyleProvider } from "@ant-design/cssinjs";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * (60 * 60), // 1 hour
    },
  },
});

const notFound = async () => {
  throw new Response("Not Found", { status: 404 });
};

const router = createHashRouter(
  createRoutesFromElements(
    <Route path="/" element={<Root />} errorElement={<RootErrorPage />}>
      <Route path="projects">
        <Route index loader={notFound} />
        <Route
          path=":projectSlug"
          id="project"
          loader={ProjectLoader(queryClient)}
        >
          <Route index loader={notFound} />
          <Route path="phases" element={<ProjectRoot />}>
            <Route index loader={notFound} />
            <Route path=":phaseSlug" element={<ProjectRoot />}>
              <Route
                index
                element={<PhasePage />}
                loader={PhaseLoader(queryClient)}
                id="phase"
              />
              <Route path="favorieten" element={<Favorites />} />
              <Route
                path="plots/:plotId"
                element={<PlotPage />}
                id="plot"
                loader={PlotLoader(queryClient)}
              />
            </Route>
          </Route>
        </Route>
      </Route>
    </Route>
  )
);

export default function App() {
  /**
   * Fontawesome adds its CSS to the page <head> by default.
   * Because we're in a closed-off shadow DOM, we need to disable this behavior.
   */
  config.autoAddCss = false;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [shadow, setShadow] = useState<any>(null);
  const backend = new URL(process.env.REACT_APP_API_PROJECT_ENDPOINT!);

  useEffect(() => {
    setShadow(wrapperRef?.current?.parentNode);
  }, []);

  return (
    <div id="dom-wrapper" ref={wrapperRef}>
      {/* We have to use the StyleProvider to force the Antd styling into the Shadow DOM */}
      {shadow && (
        <StyleProvider container={shadow}>
          {/* Re-add Fontawesome CSS */}
          <style rel="stylesheet">{dom.css()}</style>

          <link
            rel="preconnect"
            href="https://amvest.eu-central-1.linodeobjects.com"
          />

          <link rel="preconnect" href={`${backend.origin}`} />

          <link
            rel="preconnect"
            href={`https://${process.env.REACT_APP_ALGOLIA_APP_ID}.algolia.net`}
          />

          {/* Load app */}
          <React.StrictMode>
            <QueryClientProvider client={queryClient}>
              <NotificationProvider>
                <RouterProvider router={router} />
              </NotificationProvider>
            </QueryClientProvider>
          </React.StrictMode>
        </StyleProvider>
      )}
    </div>
  );
}
