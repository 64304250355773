import { Media } from "@/types";
import { faArrowsFromLine } from "@awesome.me/kit-b9851c3d09/icons/classic/regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type PreviewProps = {
  background: Media;
  handleClick: () => void;
  children?: React.ReactNode;
};

export default function Preview({
  background,
  handleClick,
  children,
}: Readonly<PreviewProps>) {
  return (
    <div className="flex items-center justify-center h-full">
      <button className="absolute h-auto" onClick={handleClick}>
        <div className="inline-flex items-center justify-center mx-1 text-lg transition-colors translate-y-1 bg-white rounded-full shadow-xl w-13 h-13 text-primary hover:bg-primary hover:text-primary-contrast">
          <FontAwesomeIcon icon={faArrowsFromLine} className="mx-2" />
        </div>

        <span className="block px-3 small !leading-7 rounded text-primary bg-primary-contrast/75">
          {children}
        </span>
      </button>
      <img
        className="object-cover w-full h-full"
        src={background.url}
        alt="preview of the map"
        fetchPriority="high"
      />
    </div>
  );
}
