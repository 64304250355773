import { Hotspot, Layer, Plot } from "@/types";

/**
 * Recursively generates an array of all layers in the project
 */
const getDescendantsRecursively = (parent: Layer, allLayers: Layer[]): any => {
  const children = allLayers.filter((item) => parent.id === item.parent_id);

  if (children.length === 0) {
    return [];
  }

  return children.concat(
    children
      .map((child) => getDescendantsRecursively(child, allLayers))
      .filter((child) => child.length > 0)
  );
};

const getLayerHotspotColor = (
  layer: Layer,
  layers: Layer[],
  plots: { [key: Plot["id"]]: Plot },
  hotspots: Hotspot[]
) => {
  const descendants = [
    // We need to add the current layer to the list as well,
    // or we won't include plots on the layer linked to the current hotspot
    layer,
    ...getDescendantsRecursively(layer, layers),
  ]
    .map((item) => item.id)
    .filter((id) => id !== undefined);

  const plotIds = hotspots
    // All plot hotspots
    .filter((item) => item.entity_type === "App\\Models\\Plot")
    // On any of the decendant layers
    .filter((item) => descendants.includes(item.layer_id))
    // Grab only the plot id
    .map((item) => item.entity_id);

  const plotStatuses = plots
    // Only the plots on descendant layers
    .filter((item) => plotIds.includes(item.id))
    // Grab only the status
    .map((item) => item.status);

  // Remove duplicate statuses
  return [...new Set(plotStatuses)];
};

/**
 * Generate an array layers that fit within (hardcoded) bounds.
 */
const generateWindow = (current: number, layers: Layer[]) => {
  const bounds = {
    range: 2,
    min: 0,
    max: layers.length,
  };

  if (layers.length <= bounds.range * 2) {
    return {
      slidingWindow: layers.toReversed(),
      bounds,
    };
  }

  let start = current - bounds.range;
  let end = current + bounds.range + 1;

  // Pad the end if we're on the first couple of layers
  if (start <= bounds.min) {
    const offset = start * -1;

    start = bounds.min;
    end = end + offset;
  }

  // Pad the start if we're on the last couple of layers
  if (end >= bounds.max) {
    const offset = end - bounds.max;

    end = bounds.max;
    start = start - offset;
  }

  return {
    slidingWindow: layers.slice(start, end),
    bounds: bounds,
  };
};

export { generateWindow, getDescendantsRecursively, getLayerHotspotColor };
