import { defaults } from "@/config/defaults";
import { getCoordinates } from "@/services/Hotspot";
import { Hotspot as HotspotType, Media, Status } from "@/types";
import { Polygon as LeafletPolygon, PathOptions } from "leaflet";
import { useEffect, useState } from "react";
import { Polygon } from "react-leaflet";
import { useSearchParams } from "react-router-dom";

type HotspotProps = {
  hotspot: HotspotType;
  status: Status;
  active: boolean;
  matches: boolean;
  background: Media;
};

export default function Hotspot({
  hotspot,
  status,
  active,
  matches,
  background,
}: Readonly<HotspotProps>) {
  const [searchParams, setSearchParams] = useSearchParams();
  const coordinates = getCoordinates(hotspot, background);
  const opacitySettings = defaults.hotspot.opacitySettings;
  const pathOptions = defaults.hotspot.pathOptions;

  const onClick = (hotspot: HotspotType) => {
    switch (hotspot.entity_type) {
      case "App\\Models\\Plot":
        searchParams.set("hotspot", hotspot.id.toString());
        setSearchParams(searchParams);
        break;
      case "App\\Models\\Layer":
        searchParams.set("layer", hotspot.entity_id.toString());
        setSearchParams(searchParams);
        break;
      case "App\\Models\\Url":
        window.open(hotspot.url, "_blank");
        break;
    }
  };

  const onMouseOver = (e: any) => {
    const target: LeafletPolygon = e.target;
    target.setStyle({
      ...getAttributes(),
      fillOpacity: opacitySettings.active,
    });
  };

  const onMouseOut = (e: any) => {
    const target: LeafletPolygon = e.target;
    target.setStyle(getAttributes());
  };

  const plotAttributes = (
    active: boolean,
    matches: boolean,
    status: Status
  ) => {
    return {
      color: pathOptions.color,
      fillColor: matches ? status.color : pathOptions.fillColor,
      fillOpacity: active
        ? opacitySettings.active
        : matches
        ? opacitySettings.matches
        : opacitySettings.default,
    };
  };

  // const layerAttributes = (layer: Layer) => {
  //   const plotStatuses = getLayerHotspotColor(
  //     layer,
  //     layers,
  //     filteredPlots,
  //     hotspots
  //   );

  //   if (plotStatuses.includes("beschikbaar")) {
  //     status = statuses["beschikbaar"];
  //   } else if (plotStatuses.includes("onder-optie")) {
  //     status = statuses["onder-optie"];
  //   } else if (plotStatuses.includes("onbeschikbaar")) {
  //     status = statuses["onbeschikbaar"];
  //   }

  //   return {
  //     fillColor: status.color,
  //     fillOpacity: active
  //       ? opacitySettings.active
  //       : matches
  //       ? opacitySettings.matches
  //       : opacitySettings.default,
  //   };
  // };

  const getAttributes = (): PathOptions => {
    return hotspot.entity_type === "App\\Models\\Plot"
      ? plotAttributes(active, matches, status)
      : // : hotspot.entity_type === "App\\Models\\Layer"
        // ? layerAttributes(entity as Layer)
        defaults.hotspot.pathOptions;
  };

  const [attributes, setAttributes] = useState<PathOptions>(getAttributes());

  useEffect(() => {
    setAttributes(getAttributes());
  }, [active, matches]);

  return (
    coordinates && (
      <Polygon
        positions={coordinates}
        pathOptions={attributes}
        className="hotspot"
        bubblingMouseEvents={false}
        eventHandlers={{
          click: () => onClick(hotspot),
          mouseover: (e) => onMouseOver(e),
          mouseout: (e) => onMouseOut(e),
        }}
      />
    )
  );
}
