import { Floorplan } from "@/types";
// import Carousel from "../Carousel";
import Fancybox from "../Fancybox";

type FloorPlanType = {
  images: Floorplan[];
  className?: string;
};

export default function Floorplans({
  images,
  ...rest
}: Readonly<FloorPlanType>) {
  return (
    <Fancybox {...rest}>
      {/* <Carousel
        options={{
          infinite: true,
        }}
      > */}
      <>
        {images.map((image, index) => {
          const { original, srcset, name } = image;

          return (
            <button
              key={original}
              className="f-carousel__slide"
              data-fancybox="gallery"
              data-src={original}
              data-srcset={srcset}
              data-sizes="200dvw"
            >
              <img
                src={original}
                srcSet={srcset}
                sizes="(min-width: 86rem) 655px, 100dvw"
                alt={name}
                className="object-cover object-center w-full"
                loading={index === 0 ? "eager" : "lazy"}
                width="655"
                height="400"
              />
            </button>
          );
        })}
      </>
      {/* </Carousel> */}
    </Fancybox>
  );
}
