import {
  faArrowRight,
  faTimes,
} from "@awesome.me/kit-b9851c3d09/icons/classic/regular";
import { faTriangleExclamation } from "@awesome.me/kit-b9851c3d09/icons/classic/solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";
import Grid from "./Grid";
import Link from "./Link";

type NotificationBarPropsType = {
  children?: ReactNode;
  onClose?: () => void; 
};

export default function NotificationBar({
  children,
  onClose, 
}: NotificationBarPropsType) {
  const { projectSlug, phaseSlug } = useParams();
  const prefix = `${process.env.NODE_ENV}-${projectSlug}-${phaseSlug}`;
  const wishlistBarKey = `${prefix}-notification-bar`;

  const [closed, setClosed] = useLocalStorage<number | null>(
    wishlistBarKey,
    null
  );

  useEffect(() => {
    if (closed !== null) return;

    setClosed(new Date().getTime());
  }, [closed, setClosed]); 

  const handleOnClick = () => {
    if (onClose) {
      onClose(); 
    } else {
      setClosed(new Date().getTime()); 
    }
  };

  return (
    <Grid className="relative z-50 flex flex-row-reverse w-full">
      <div className="z-50 flex justify-between max-w-[28.75rem] bg-white rounded-xl border border-black/5 shadow-notificationBar py-4 px-6 gap-x-4 items-start bottom-12 fixed">
        <FontAwesomeIcon
          icon={faTriangleExclamation}
          className="text-[#F8B20B] h3 !leading-6"
        />

        <div className="flex flex-col items-start gap-y-2">
          <div className="flex items-center gap-x-4 text-balance">
            {children}
          </div>

          <Link
            to={`/projects/${projectSlug}/phases/${phaseSlug}/favorieten`}
            variant="primary"
            className="flex gap-x-2 grow-0 shrink-0"
          >
            Naar jouw favorieten
            <FontAwesomeIcon icon={faArrowRight} />
          </Link>
        </div>

        <button onClick={handleOnClick}>
          <FontAwesomeIcon icon={faTimes} className="w-3 h-4 font-black" />
        </button>
      </div>
    </Grid>
  );
}
