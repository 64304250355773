import { Count } from "@/components";
import { Plot } from "@/types";
import { Select } from "antd";
import { useSearchParams } from "react-router-dom";

type SunPositionFilterType = {
  values: Plot["sun_position_outdoor"][];
};

export default function SunPositionFilter({
  values,
}: Readonly<SunPositionFilterType>) {
  const [searchParams, setSearchParams] = useSearchParams();

  const positions = values
    .sort((a, b) => parseInt(a) - parseInt(b))
    .map((position) => ({ label: position, value: position }));

  const onChange = (value: string[]) => {
    if (value.toString() === "") {
      searchParams.delete("sun_position_outdoor");
    } else {
      searchParams.set("sun_position_outdoor", value.toString());
    }

    setSearchParams(searchParams);
  };
  const filterValue =
    searchParams.get("sun_position_outdoor")?.split(",") || values;

  const labelRender = () => {
    return (
      <span className="flex items-center justify-center gap-2 group">
        Zonligging
        {values.length !== filterValue.length && (
          <Count>{filterValue.length}</Count>
        )}
      </span>
    );
  };

  return (
    <fieldset className="flex">
      <Select
        className="w-full"
        mode="multiple"
        options={positions}
        value={filterValue}
        onChange={onChange}
        showSearch={false}
        maxTagCount={0}
        maxTagPlaceholder={labelRender}
        getPopupContainer={(e) => e.closest("main")}
      />
    </fieldset>
  );
}
