import { DetailedPlot, Hotspot, Layer, Plot, Status } from "@/types";
import {
  faBellConcierge,
  faBolt,
  faBuilding,
  faCar,
  faChair,
  faChairOffice,
  faCircleEuro,
  faClouds,
  faCompass,
  faCube,
  faDoorClosed,
  faDrawSquare,
  faFaucet,
  faHashtag,
  faHeat,
  faHouseWindow,
  faLampStreet,
  faLeaf,
  faParkingCircle,
  faShower,
  faSnowflake,
  faSun,
  faVectorSquare,
  faWindowFrame,
} from "@awesome.me/kit-b9851c3d09/icons/classic/regular";
import {
  currency as formatCurrency,
  period as formatPeriod,
  price as formatPrice,
} from "./Numbers";

const groupByLayer = (
  hotspots: Hotspot[],
  layers: { [key: Layer["id"]]: Layer },
  plots: { [key: Plot["id"]]: Plot }
) => {
  return hotspots.reduce<{ [key: Layer["id"]]: Plot[] }>(
    (accumulator, hotspot) => {
      const key: number = hotspot.entity_id;

      if (hotspot.entity_type !== "App\\Models\\Plot") return accumulator;
      if (plots[key] === undefined) return accumulator;

      const layer = layers[hotspot.layer_id];

      if (accumulator[layer.id] === undefined) {
        accumulator[layer.id] = [];
      }

      accumulator[layer.id].push(plots[key]);
      return accumulator;
    },
    {}
  );
};

function groupPlotValuesByKey(plots: Plot[]) {
  const validFields: (keyof Plot)[] = [
    "price",
    "house_type_name",
    "status",
    "room_count",
    "sun_position_outdoor",
    "living_surface",
  ];

  const plotFields = validFields.map((key) => {
    return [key, new Set<Plot[typeof key]>()];
  });

  return plots.reduce((accumulator, plot) => {
    for (const field of validFields) {
      const value = plot[field];
      if (value === null) continue;
      accumulator[field].add(value);
    }

    return accumulator;
  }, Object.fromEntries(plotFields)) as {
    [key in (typeof validFields)[number]]: Set<Plot[key]>;
  };
}

const isAvailable = (status: Status): boolean => {
  const unavailableStatuses = ["onbeschikbaar", "verkocht", "verhuurd"];
  return !unavailableStatuses.includes(status.slug);
};

const formatName = (plot: Plot | DetailedPlot) => {
  const { project_type, name, house_number } = plot;

  switch (project_type) {
    case "rent":
      return `Huisnr. ${house_number}`;
    case "sale":
    default:
      return `Bwnr. ${name}`;
  }
};

const getProperties = (plot: DetailedPlot) => {
  const {
    bathroom_facilities,
    cooler_type,
    energy_label,
    energy_performance,
    floor,
    heater_type,
    house_number,
    house_type_name,
    isolation,
    living_surface,
    location_services,
    name,
    outdoor_type,
    parking,
    parking_type,
    plot_surface_outdoor,
    project_type,
    purchase_condition,
    residential_floors,
    roof_type,
    room_count,
    sanitary_facilities,
    service_costs,
    services,
    street,
    sun_position_outdoor,
    type,
    volume,
    warm_water_type,
  } = plot;

  const format = (value: string | null, type: 2 | 3) =>
    value ? (
      <>
        {value} m<sup>{type}</sup>
      </>
    ) : null;

  return {
    Algemeen: [
      {
        label:
          project_type === "sale"
            ? "Koopsom v.o.n."
            : project_type === "rent"
            ? "Huurprijs"
            : null,
        name: "price",
        value: formatPrice(plot),
        icon: faCircleEuro,
      },
      {
        label: "Erfpacht of eigen grond",
        name: "purchase_condition",
        value: purchase_condition ? (
          <span dangerouslySetInnerHTML={{ __html: purchase_condition }}></span>
        ) : null,
        icon: faDrawSquare,
      },
      {
        label:
          project_type === "sale"
            ? "Inschatting VvE-bijdrage"
            : project_type === "rent"
            ? "Servicekosten"
            : null,
        name: "service_costs",
        value: service_costs
          ? `${formatCurrency(parseInt(service_costs))} ${formatPeriod(
              project_type
            )}`
          : null,
        icon: faBellConcierge,
      },
    ],
    Indeling: [
      {
        label: "Aantal kamers",
        name: "room_count",
        value: room_count ?? null,
        icon: faChair,
      },
      {
        label: "Soort dak",
        name: "roof_type",
        value: roof_type,
      },
      {
        label: "Woonlaag",
        name: "floor",
        value: floor ?? null,
        icon: faBuilding,
      },
      {
        label: "Aantal woonlagen",
        name: "residential_floors",
        value: residential_floors ?? null,
        icon: faChairOffice,
      },
      {
        label: "Sanitair",
        name: "sanitary_facilities",
        value: sanitary_facilities ?? null,
        icon: faShower,
      },
      {
        label: "Badkamer voorzieningen",
        name: "bathroom_facilities",
        value: bathroom_facilities ?? null,
        icon: faFaucet,
      },
    ],
    "Oppervlakten & inhoud": [
      {
        label: "Woonoppervlakte in gbo",
        name: "living_surface",
        value: <span>circa {format(living_surface, 2)}</span>,
        icon: faVectorSquare,
      },
      {
        label: "Inhoud",
        name: "volume",
        value: format(volume, 3),
        icon: faCube,
      },
    ],
    Parkeren: [
      {
        label: "Soort parkeerplaats",
        name: "parking_type",
        value: parking_type ?? null,
        icon: faParkingCircle,
      },
      {
        label: "Toegewezen parkeerplaatsen",
        name: "parking",
        value: parking ?? null,
        icon: faCar,
      },
    ],
    Woning: [
      {
        label: "Woningtype",
        name: "house_type_name",
        value: house_type_name ?? null,
        icon: faWindowFrame,
      },
      {
        label: "Woningsoort",
        name: "type",
        value: type ?? null,
        icon: faHouseWindow,
      },
      {
        label: "Straat",
        name: "street",
        value: street ?? null,
        icon: faLampStreet,
      },
      {
        label: "Huisnummer",
        name: "house_number",
        value: house_number ?? null,
        icon: faDoorClosed,
      },
      {
        label: "Bouwnummer",
        name: "name",
        value: name ?? null,
        icon: faHashtag,
      },
    ],
    Buitenruimte: [
      {
        label: "Type buitenruimte",
        name: "outdoor_type",
        value: outdoor_type ?? null,
        icon: faClouds,
      },
      {
        label: "Zonligging buitenruimte",
        name: "sun_position_outdoor",
        value: sun_position_outdoor,
        icon: faCompass,
      },
      {
        label: "Oppervlakte buitenruimte",
        name: "plot_surface_outdoor",
        value: plot_surface_outdoor ? (
          <span>circa {format(plot_surface_outdoor, 2)}</span>
        ) : null,
        icon: faVectorSquare,
      },
    ],
    Voorzieningen: [
      {
        label: "Voorzieningen",
        name: "services",
        value: services ?? null,
        icon: faBellConcierge,
      },
      {
        label: "Ligging",
        name: "services",
        value: location_services ?? null,
        icon: faSun,
      },
    ],
    Energie: [
      {
        label: "Voorlopig energielabel",
        name: "energy_label",
        value: energy_label ?? null,
        icon: faLeaf,
      },
      {
        label: "Energie prestatie",
        name: "energy_performance",
        value: energy_performance ?? null,
        icon: faBolt,
      },
      {
        label: "Verwarming",
        name: "heater_type",
        value: heater_type ?? null,
        icon: faHeat,
      },
      {
        label: "Koeling",
        name: "cooler_type",
        value: cooler_type ?? null,
        icon: faSnowflake,
      },
      {
        label: "Warm water",
        name: "warm_water_type",
        value: warm_water_type ?? null,
      },
      {
        label: "Isolatie",
        name: "isolation",
        value: isolation ?? null,
      },
    ],
  };
};

export {
  getProperties,
  groupByLayer,
  groupPlotValuesByKey,
  isAvailable,
  formatName,
};
