import { currency } from "@/services";
import { faTimes } from "@awesome.me/kit-b9851c3d09/icons/classic/regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { useSearchParams } from "react-router-dom";

const formatters = {
  house_type_name: (value: string) => <span>{value}</span>,
  min_price: (value: string) => <span>min {currency(parseInt(value))}</span>,
  max_price: (value: string) => <span>max {currency(parseInt(value))}</span>,
  statuses: (value: string) => (
    <span className="first-letter:capitalize">{value.replace("-", " ")}</span>
  ),
  room_count: (value: string) => <span>{value}+ kamers</span>,
  sun_position_outdoor: (value: string) => <span>{value}</span>,
  living_surface: (value: string) => (
    <span>
      Vanaf {value} m<sup>2</sup>
    </span>
  ),
};

type TagProps = {
  filterSlug: string;
  className?: string;
};

export default function Tags({ filterSlug, className }: Readonly<TagProps>) {
  const [searchParams, setSearchParams] = useSearchParams();
  const values = searchParams.get(filterSlug) ?? "";
  const type = filterSlug as keyof typeof formatters;
  const formatted = formatters[type];

  if (!formatted) return null;

  const onClick = (value: string) => {
    const updated = values
      .split(",")
      .filter((v) => v !== value)
      .join(",");

    if (updated === "") {
      searchParams.delete(filterSlug);
    } else {
      searchParams.set(filterSlug, updated);
    }

    setSearchParams(searchParams);
  };

  return (
    values && (
      <div className="flex flex-wrap gap-1 shrink-0">
        {values
          .split(",")
          .filter((value) => value)
          .map((value) => {
            return (
              <button
                key={`${filterSlug}-${value}`}
                onClick={() => onClick(value)}
                className={clsx(
                  className,
                  "flex items-center text-sm rounded shrink-0 grow-0"
                )}
              >
                <span>{formatted(value)}</span>
                <FontAwesomeIcon className="text-primary" icon={faTimes} />
              </button>
            );
          })}
      </div>
    )
  );
}
