import { buttonClasses, buttonIconClasses } from "@/services";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { MouseEventHandler } from "react";

type ButtonProps = {
  children: any;
  variant: "primary" | "secondary" | "ghost";
  className?: string;
  icon?: IconProp;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  ref?: any;
  as?: any;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export default function Button({
  children,
  variant = "primary",
  className,
  icon,
  onClick,
  as = "button",
  ...rest
}: Readonly<ButtonProps>) {
  const hasIcon = !!icon;
  const Component = as || "button";

  return (
    <Component
      className={clsx(className, buttonClasses(variant))}
      onClick={onClick}
      {...rest}
    >
      {hasIcon && (
        <FontAwesomeIcon icon={icon} className={buttonIconClasses(variant)} />
      )}
      {children}
    </Component>
  );
}
