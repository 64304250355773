import { slugify } from "@/services";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

type PlotProperty = {
  label: string | null;
  name: string;
  value: string | number | JSX.Element | null;
  icon?: IconDefinition;
};

type PropertiesProps = {
  properties: { [key: string]: PlotProperty[] };
};

export default function Properties({ properties }: Readonly<PropertiesProps>) {
  return (
    <ul className="grid grid-cols-1 gap-x-4 gap-y-8 col-span-full xs:grid-cols-2 xs:gap-x-15 xs:gap-y-12 lg:col-start-2 lg:col-end-14">
      {Object.keys(properties).map((section: string) => {
        const key = section as keyof typeof properties;
        const validProperties = properties[key].filter(
          (property: PlotProperty) =>
            property.value !== null && property.value !== undefined
        );
        return (
          validProperties.length > 0 && (
            <li key={slugify(section)} className="col-span-full xs:col-span-1">
              <h3 className="mb-4 h3 text-primary lg:mb-5">{section}</h3>

              <ul className="flex flex-col gap-y-4 lg:gap-y-5">
                {validProperties.map(
                  ({ name, icon, label, value }: PlotProperty) => (
                    <li key={slugify(name)} className="flex flex-col gap-y-1.5">
                      <div className="flex items-center gap-x-4">
                        {icon && (
                          <FontAwesomeIcon icon={icon} className="w-4" />
                        )}

                        {label && (
                          <span className="block font-bold small">{label}</span>
                        )}
                      </div>

                      <span
                        className={clsx(
                          "block text-[0.9375rem] leading-tight",
                          icon && "ml-8"
                        )}
                      >
                        {typeof value === "function" ? value : value}
                      </span>
                    </li>
                  )
                )}
              </ul>
            </li>
          )
        );
      })}
    </ul>
  );
}
