import { Count } from "@/components";
import { Plot } from "@/types";
import { Select } from "antd";
import clsx from "clsx";
import { useSearchParams } from "react-router-dom";

type HousetypeFilterProps = {
  values: Plot["house_type_name"][];
  className?: string;
};

export default function HousetypeFilter({
  values,
  className,
}: Readonly<HousetypeFilterProps>) {
  const [searchParams, setSearchParams] = useSearchParams();

  const positions = values
    .sort((a, b) => parseInt(a) - parseInt(b))
    .map((position) => ({ label: position, value: position }));

  const filterValue = searchParams.get("house_type_name")?.split(",") || values;

  const onChange = (value: string[]) => {
    const updated = value.length !== 0 ? value.join(",") : "";

    if (updated.toString() === "") {
      searchParams.delete("house_type_name");
    } else {
      searchParams.set("house_type_name", updated.toString());
    }

    setSearchParams(searchParams);
  };

  const labelRender = () => {
    return (
      <span className="flex items-center justify-center gap-2 group">
        Woningtype
        {values.length !== filterValue.length && (
          <Count>{filterValue.length}</Count>
        )}
      </span>
    );
  };
  return (
    <fieldset className="flex">
      <Select
        labelRender={labelRender}
        mode="multiple"
        value={filterValue}
        defaultValue={["Woningtype"]}
        options={positions}
        showSearch={false}
        className={clsx("h-full", className)}
        onChange={onChange}
        maxTagCount={0}
        maxTagPlaceholder={labelRender}
        getPopupContainer={(e) => e.closest("main")}
      />
    </fieldset>
  );
}
