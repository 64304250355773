import { Hotspot, Layer, Plot, Status } from "@/types";

export function keyBy<T extends Plot | Layer | Hotspot | Status>(
  items: T[],
  key: keyof T & string
): { [key: string]: T } {
  if (items.length === 0) return {};

  const keys = Object.keys(items[0]);

  if (keys.length === 0) {
    console.error(`Object doesn't have any keys`, items[0]);
    return {};
  }

  if (!keys.includes(key)) {
    console.error(
      `Key ${key} does not exist on the first item in $items`,
      items[0]
    );
    return {};
  }

  return items.reduce<{ [key: string]: T }>((acc, item: T) => {
    const k = item[key];
    acc[k] = item;
    return acc;
  }, {});
}
