import { generateWindow } from "@/services";
import { Layer } from "@/types";
import {
  faAngleDown,
  faAngleUp,
} from "@awesome.me/kit-b9851c3d09/icons/classic/regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

import { useSearchParams } from "react-router-dom";

type ElevatorProps = {
  layers: Layer[];
  activeLayer: Layer;
};

export default function Elevator({
  layers,
  activeLayer,
}: Readonly<ElevatorProps>) {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentIndex = layers.findIndex((layer) => layer.id === activeLayer.id);

  const { slidingWindow, bounds } = generateWindow(currentIndex, layers);

  const onLayerClick = (layer: Layer) => changeLayer(layer);

  const onButtonClick = (target: "prev" | "next") => {
    let layer =
      target === "prev" ? layers[currentIndex - 1] : layers[currentIndex + 1];
    if (!layer) return;

    changeLayer(layer);
  };

  const changeLayer = (layer: Layer) => {
    searchParams.set("layer", layer.id.toString());
    searchParams.delete("hotspot");
    setSearchParams(searchParams);
  };

  return (
    <ul className="absolute top-1/2 -translate-y-1/2 z-20 flex flex-col w-10 left-4 gap-2.5 select-none">
      <li className="text-sm">
        <button
          className={clsx(
            "group block text-xl text-white text-center rounded-md w-full hocus:bg-primary-contrast hocus:text-primary",

            currentIndex === bounds.max - 1 && "opacity-0 pointer-events-none"
          )}
          onClick={() => onButtonClick("next")}
          disabled={currentIndex === bounds.max - 1}
        >
          <FontAwesomeIcon icon={faAngleUp} />
        </button>
      </li>

      {slidingWindow.toReversed().map((layer) => (
        <li key={layer.id} className={clsx("text-sm")}>
          <button
            className={clsx(
              "block text-center rounded-md w-full py-1.5 ",
              "group hocus:bg-primary-contrast hocus:text-primary",
              activeLayer.id !== layer.id && "text-white",
              activeLayer.id === layer.id &&
                "font-bold text-primary bg-primary-contrast"
            )}
            onClick={() => onLayerClick(layer)}
            disabled={activeLayer.id === layer.id}
          >
            {layer.name}
          </button>
        </li>
      ))}

      <li className="text-sm">
        <button
          className={clsx(
            "block text-white text-center rounded-md w-full text-xl hocus:bg-primary-contrast hocus:text-primary",
            currentIndex === bounds.min && "opacity-0 pointer-events-none"
          )}
          onClick={() => onButtonClick("prev")}
          disabled={currentIndex === bounds.min}
        >
          <FontAwesomeIcon
            icon={faAngleDown}
            className="group-hocus:text-primary"
          />
        </button>
      </li>
    </ul>
  );
}
