import { faGripDotsVertical } from "@awesome.me/kit-b9851c3d09/icons/classic/solid";
import {
  faCaretDown,
  faCaretUp,
} from "@awesome.me/kit-b9851c3d09/icons/classic/solid";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import React from "react";

type SortableItemProps = {
  id: string;
  index: number;
  length: number;
  onUpClick: () => void;
  onDownClick: () => void;
  children: React.ReactNode;
};

export function SortableItem({
  id,
  index,
  length,
  onUpClick,
  onDownClick,
  children,
}: SortableItemProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging && 0.2,
  };

  const isFirst = index === 0;
  const isLast = index === length - 1;

  return (
    <div ref={setNodeRef} style={style} className="relative">
      <div className="h4 absolute -left-14 -translate-x-full flex justify-center items-center h-[9.75rem] select-none">
        <span>{index + 1}</span>
      </div>

      <div className="absolute left-0 flex flex-col justify-center -translate-x-full gap-y-3">
        <button
          onClick={onDownClick}
          className={clsx(
            "flex items-center justify-center h-11 w-14 hocus:bg-gray-100",
            isFirst && "text-black/20 cursor-not-allowed",
            !isFirst && "text-primary"
          )}
          disabled={isFirst}
        >
          <FontAwesomeIcon icon={faCaretUp} />
        </button>

        <button
          {...listeners}
          {...attributes}
          className="flex items-center justify-center h-11 w-14 text-black/20 cursor-grab h4 hocus:bg-gray-100"
        >
          <FontAwesomeIcon icon={faGripDotsVertical} />
        </button>

        <button
          onClick={onUpClick}
          className={clsx(
            "flex items-center justify-center h-11 w-14 hocus:bg-gray-100",
            isLast && "text-black/20 cursor-not-allowed",
            !isLast && "text-primary"
          )}
          disabled={isLast}
        >
          <FontAwesomeIcon icon={faCaretDown} />
        </button>
      </div>

      {children}
    </div>
  );
}
