import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import r2wc from "@r2wc/react-to-web-component";
import "./index.css";
import Webcomponent from "@/components/Webcomponent";

const Woningzoeker = r2wc(Webcomponent, {
  shadow: "open",
  props: {
    url: "string",
  },
});

customElements.define("amvest-woningzoeker", Woningzoeker);

const root = ReactDOM.createRoot(
  document.getElementById("woningzoeker-app") as HTMLElement
);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
