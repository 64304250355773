import { useEffect } from "react";
import App from "@/App";

const getStylesheetOrigin = (woningzoekerUrl: string) => {
  const { origin } = new URL(woningzoekerUrl);

  return origin;
};

const getHash = (woningzoekerUrl: string) => {
  const { hash } = new URL(woningzoekerUrl);

  return hash;
};

type WebComponentProps = {
  url: string;
};

const location = window.location.toString();
export default function Webcomponent({ url = location }: WebComponentProps) {
  useEffect(() => {
    window.location.hash = getHash(url);
  }, [url]);

  const stylesheetUrl = getStylesheetOrigin(url);

  return (
    <div id="webcomponent-wrapper">
      <link rel="stylesheet" href={`${stylesheetUrl}/static/css/main.css`} />

      <App />
    </div>
  );
}
