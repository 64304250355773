import { Plot, Status } from "@/types";

const sortAlphabetically = (a: string, b: string) => {
  if (parseInt(a) < parseInt(b)) return -1;
  if (parseInt(a) > parseInt(b)) return 1;
  return 0;
};

/**
 * Order all plots based on the sorting type.
 * @param {Array} plots All plots to sort.
 * @param {string} type The type of sorting to use.
 * @param {Array} statuses The project's plots statuses.
 * @returns {Array} A list of sorted plots.
 */
function sort(plots: Plot[], type: string, statuses: Status[]): Plot[] {
  return plots.sort((a, b) => {
    if (type === "costASC") return sortPlotsByAscCost(a, b, statuses);
    else if (type === "costDESC") return sortPlotsByDescCost(a, b, statuses);
    else if (type === "number") return ascending(a.name, b.name);
    else if (type === "type")
      return ascending(a.house_type_name, b.house_type_name);
    else if (type === "livingSurfaceASC")
      return ascending(a.living_surface, b.living_surface);
    else if (type === "livingSurfaceDESC")
      return descending(a.living_surface, b.living_surface);
    else if (type === "plotSurfaceASC")
      return ascending(a.living_surface, b.living_surface);
    else if (type === "plotSurfaceDESC")
      return descending(a.living_surface, b.living_surface);
    else if (type === "status")
      return ascending(statuses[a.status].name, statuses[b.status].name);

    return -1;
  });
}

/**
 * ASCENDING: Puts the lowest value on top.
 * {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare}
 */
function ascending(a: string, b: string): number {
  return a.localeCompare(b, undefined, { numeric: true, sensitivity: "base" });
}

/**
 * DESCENDING: Puts the highest value on top.
 * {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare}
 */
function descending(a: string, b: string): number {
  return b.localeCompare(a, undefined, { numeric: true, sensitivity: "base" });
}

/**
 * Puts the lowest value on top.
 * Checks for plots with the status of 'Verkocht' and sorts them at the bottom of the list.
 * Continues to sort as usual.
 */
function sortPlotsByAscCost(a: Plot, b: Plot, sortedStatuses: any): number {
  if (
    sortedStatuses[a.status] === "verkocht" ||
    sortedStatuses[a.status] === "voorbereiden-start-verkoop"
  ) {
    return 1;
  } else if (
    sortedStatuses[b.status] === "verkocht" ||
    sortedStatuses[b.status] === "voorbereiden-start-verkoop"
  ) {
    return -1;
  }

  return a.price > b.price ? 1 : -1;
}

/**
 * Puts the highest value on top.
 * Checks for plots with the status of 'Verkocht' and sorts them at the bottom of the list.
 * Continues to sort as usual.
 */
function sortPlotsByDescCost(a: Plot, b: Plot, sortedStatuses: any): number {
  if (
    sortedStatuses[a.status] === "verkocht" ||
    sortedStatuses[a.status] === "voorbereiden-start-verkoop"
  ) {
    return 1;
  } else if (
    sortedStatuses[b.status] === "verkocht" ||
    sortedStatuses[b.status] === "voorbereiden-start-verkoop"
  ) {
    return -1;
  }

  return b.price > a.price ? 1 : -1;
}

export { sort, sortAlphabetically };
