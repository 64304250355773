import "@/components/Filter/Filter.css";
import Tags from "@/components/Filter/Tags";
import { Phase, Plot } from "@/types";
import {
  faAngleLeft,
  faSliders,
} from "@awesome.me/kit-b9851c3d09/icons/classic/regular";
import { faCircleCheck } from "@awesome.me/kit-b9851c3d09/icons/classic/solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MouseEventHandler, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Button from "../Button";
import HousetypeFilter from "./HousetypeFilter";
import PriceFilter from "./PriceFilter";
import RoomFilter from "./RoomFilter";
import StatusFilter from "./StatusFilter";
import SunPositionFilter from "./SunPositionFilter";
import SurfaceFilter from "./SurfaceFilter";
import clsx from "clsx";

type SmallScreenProps = {
  value: { [key in keyof Plot]: Set<Plot[key]> };
  statuses: Phase["statuses"];
  onClick: MouseEventHandler<HTMLButtonElement>;
  mapStatus: boolean;
  enabledFilters: string[];
  className?: string;
};

const SmallScreen = ({
  value,
  statuses,
  onClick,
  mapStatus,
  enabledFilters,
  className,
}: Readonly<SmallScreenProps>) => {
  const [showAllFilters, setShowAllFilters] = useState(false);
  const [searchParams] = useSearchParams();
  const fieldValues = value;
  const toggleFilters = () => setShowAllFilters(!showAllFilters);

  const activeFilterCount = enabledFilters.filter((filter) =>
    searchParams.has(filter)
  ).length;

  return (
    <div className={clsx(className)}>
      <div
        className={clsx(
          "flex-wrap mx-6 overflow-hidden bg-white border rounded-lg -translate-y-1/2",
          showAllFilters ? "hidden" : "flex"
        )}
      >
        {/* Back button */}
        <BackButton onClick={onClick} mapStatus={mapStatus} />

        <div className="flex gap-1 p-1 grow">
          {/* Filters */}
          <div className="hidden xs:flex">
            <PriceFilter
              showMinPrice={false}
              values={Array.from(fieldValues.price.values())}
              className="border-none"
            />
          </div>
          <div className="hidden sm:flex">
            <RoomFilter
              values={Array.from(fieldValues.room_count.values())}
              className="border-none"
            />
          </div>
          {/* Filter menu toggle */}
          <FilterButton
            toggleFilters={toggleFilters}
            size={activeFilterCount}
            className="w-full ml-auto mr-0 grow-1 xs:grow-0 xs:w-auto"
          />
        </div>
      </div>

      <div
        className={clsx(
          "fixed z-50 inset-0 h-screen overflow-y-auto bg-white",
          showAllFilters ? "block" : "hidden"
        )}
      >
        <div className="flex items-center justify-between py-1 pl-4 pr-1 mx-4 border rounded-lg shadow-lg shadow-secondary">
          <span className="text-sm font-semibold leading-none">
            {activeFilterCount} filters actief
          </span>

          <ResultsButton
            toggleFilters={toggleFilters}
            size={activeFilterCount}
          />
        </div>

        <div className="grid mx-6 gap-y-10 mt-11 mb-14">
          {enabledFilters.includes("house_type_name") &&
            fieldValues.house_type_name.size > 0 && (
              <div className="grid gap-2.5">
                <p className="h3">Woningtype</p>
                <HousetypeFilter
                  className="w-full"
                  values={Array.from(fieldValues.house_type_name.values())}
                />

                <Tags
                  filterSlug="house_type_name"
                  className="gap-1.5 px-2 py-1.5 bg-dark/5 font-semibold hocus:bg-secondary"
                />
              </div>
            )}

          {enabledFilters.includes("min_price") &&
            enabledFilters.includes("max_price") &&
            fieldValues.price.size > 0 && (
              <div className="grid gap-2.5">
                <span className="h3">Prijs</span>
                <PriceFilter
                  showMinPrice={true}
                  values={Array.from(fieldValues.price.values())}
                />
                <div className="flex gap-2.5">
                  <Tags
                    filterSlug="min_price"
                    className="gap-1.5 px-2 py-1.5 bg-dark/5 font-semibold hocus:bg-secondary"
                  />
                  <Tags
                    filterSlug="max_price"
                    className="gap-1.5 px-2 py-1.5 bg-dark/5 font-semibold hocus:bg-secondary"
                  />
                </div>
              </div>
            )}

          {enabledFilters.includes("statuses") &&
            fieldValues.status.size > 0 && (
              <div className="grid gap-2.5">
                <span className="h3">Beschikbaarheid</span>
                <StatusFilter
                  values={Array.from(fieldValues.status.values())}
                  statuses={statuses}
                />
                <Tags
                  filterSlug="statuses"
                  className="gap-1.5 px-2 py-1.5 bg-dark/5 font-semibold hocus:bg-secondary"
                />
              </div>
            )}

          {enabledFilters.includes("room_count") &&
            fieldValues.room_count.size > 0 && (
              <div className="grid gap-2.5">
                <span className="h3">Aantal kamers</span>
                <RoomFilter
                  values={Array.from(fieldValues.room_count.values())}
                />
                <Tags
                  filterSlug="room_count"
                  className="gap-1.5 px-2 py-1.5 bg-dark/5 font-semibold hocus:bg-secondary"
                />
              </div>
            )}

          {enabledFilters.includes("sun_position_outdoor") &&
            fieldValues.sun_position_outdoor.size > 0 && (
              <div className="grid gap-2.5">
                <span className="h3">Zonligging</span>
                <SunPositionFilter
                  values={Array.from(fieldValues.sun_position_outdoor.values())}
                />
                <Tags
                  filterSlug="sun_position_outdoor"
                  className="gap-1.5 px-2 py-1.5 bg-dark/5 font-semibold hocus:bg-secondary"
                />
              </div>
            )}

          {enabledFilters.includes("living_surface") &&
            fieldValues.living_surface.size > 0 && (
              <div className="grid gap-2.5">
                <span className="h3">Woonoppervlakte</span>
                <SurfaceFilter
                  values={Array.from(fieldValues.living_surface.values())}
                />
                <Tags
                  filterSlug="living_surface"
                  className="gap-1.5 px-2 py-1.5 bg-dark/5 font-semibold hocus:bg-secondary"
                />
              </div>
            )}

          <div className="flex ">
            <ResultsButton
              toggleFilters={toggleFilters}
              size={activeFilterCount}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

type ButtonProps = {
  toggleFilters: () => void;
  size: number;
  className?: string;
};

const FilterButton = ({
  toggleFilters,
  size,
  className,
}: Readonly<ButtonProps>) => (
  <Button
    onClick={toggleFilters}
    className={className}
    variant="secondary"
    icon={faSliders}
  >
    Filters
    <span className="inline-flex items-center justify-center w-5 h-5 mx-1 text-sm leading-none rounded-full text-light bg-dark/35">
      {size}
    </span>
  </Button>
);

type BackButtonProps = {
  mapStatus: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
};

const BackButton = ({ mapStatus, onClick }: BackButtonProps) =>
  mapStatus && (
    <button
      onClick={onClick}
      className="items-center justify-center px-3 py-2 rounded-l-lg bg-white/65 backdrop-blur-xl"
    >
      <FontAwesomeIcon icon={faAngleLeft} />
    </button>
  );

const ResultsButton = ({ toggleFilters, size }: Readonly<ButtonProps>) => (
  <Button
    onClick={toggleFilters}
    variant="secondary"
    className="px-2 py-2 font-semibold rounded-md"
    icon={faCircleCheck}
  >
    Toon resultaten
    <span className="inline-flex items-center justify-center w-5 h-5 text-[13px] leading-none rounded-full text-light bg-dark/35">
      {size}
    </span>
  </Button>
);

export default SmallScreen;
