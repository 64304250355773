import { PhaseLoader, PhaseQuery } from "@/loaders";
import { Layer, Plot } from "@/types";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useRouteLoaderData, useSearchParams } from "react-router-dom";

export default function useActiveLayer(filteredPlotsByLayer: {
  [key: Layer["id"]]: Plot[];
}) {
  const { projectSlug, phaseSlug } = useRouteLoaderData("phase") as Awaited<
    ReturnType<ReturnType<typeof PhaseLoader>>
  >;

  const {
    data: { phase, layers },
  } = useSuspenseQuery(PhaseQuery(projectSlug, phaseSlug));

  const [searchParams] = useSearchParams();

  const searchParamLayer = searchParams.get("layer");
  const filteredLayerKeys = Object.keys(filteredPlotsByLayer)[0];

  const defaultLayer =
    searchParams.get("layer") ?? filteredLayerKeys ?? phase.layers[0].id;

  const [activeLayer, setActiveLayer] = useState<Layer>(layers[defaultLayer]);

  useEffect(() => {
    const layerId = searchParamLayer ?? filteredLayerKeys ?? phase.layers[0].id;

    setActiveLayer(layers[layerId]);

    return () => {
      setActiveLayer(layers[defaultLayer]);
    };
  }, [searchParamLayer, filteredLayerKeys]);

  return {
    activeLayer,
    setActiveLayer,
  };
}
